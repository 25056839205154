import { storyblokEditable } from "@storyblok/react";
import { Box, Button, Flex, GridItem, SimpleGrid, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";

interface LocationMainContentProps {
  blok: any;
  globalState: GlobalState;
  locationState: RootsLocation;
}

const LocationMainContent = ({ blok, globalState, locationState }: LocationMainContentProps):JSX.Element => {
  // this *should* always be the slug we need for the 365 page...
  const locationPageLink = globalState.locations.filter(location => location.rootsId === locationState.id)[0].pageLink.cachedUrl;
  const locationPageLinkArray = locationPageLink.split("/");
  const locationSlug = locationPageLinkArray[locationPageLinkArray.length - 1];

  return (
    <Box
      {...storyblokEditable(blok)}
      p="5"
    >
      <Text as="h2" color="brand.green.600" mb="4">{locationState?.name} Mission Trips</Text>
      <div dangerouslySetInnerHTML={{ __html: locationState?.summary }} />
      {
        locationState.landing365 === 1 && 
        <SimpleGrid 
          columns={{base: 1, lg: 2}}
        >
          <GridItem
            bgColor="brand.green.600"
            borderColor="brand.orange.600"
            borderWidth="2px"
            p="5"
            borderLeftRadius={{ base: 0, lg: "lg" }}
            borderTopRadius={{ base: 'lg', lg: 0 }}
          >
            <Text
              as="h4"
              textColor={'white'}
              textAlign="center"
              fontWeight="bold"
            >
              <FontAwesomeIcon icon="calendar-alt" style={{marginRight: '5px'}} />
              Year-Round Ministry
            </Text>
            <Text color="white">
              PPM&apos;s ministry is an ongoing effort to build up, encourage, and assist the local Church 365 
              days a year. Nearly every week of every year, we have a team serving short-term somewhere in the 
              world. Our partner pastors and ministry leaders receive ministry support, training, and encouragement 
              when mission teams aren&apos;t on the ground through our partner support program.
            </Text>
          </GridItem>
          <GridItem
            bgColor="brand.orange.600"
            borderRightRadius={{base: 0, lg: "lg"}}
            borderBottomRadius={{ base: 'lg', lg: 0 }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems={'center'}
            p="5"
          >
            <Text
              as="h4"
              textColor={'white'}
              textAlign="center"
              fontWeight="bold"
            >
              <FontAwesomeIcon icon="circle-notch" style={{ marginRight: '5px' }} />
              PPM365
            </Text>
            <Text color="white">
              A continuous effort to build up, encourage, and assist the local Church 365 days a year.
            </Text>
            <Text color="white">
              Read more about how PPM365, a church sponsorship program, is making an impact in {locationState.theName}, and around the world.
            </Text>
            <Link href={`/365/${locationSlug}`} passHref legacyBehavior>
              <Button 
                as="a"
                sx={{'&:hover': {textDecoration: 'none'}}}
                colorScheme="brand.darkgray"
                leftIcon={<FontAwesomeIcon icon="info-circle" />}
              >
                Click For More Info
              </Button>
            </Link>
          </GridItem>
        </SimpleGrid>
      }
    </Box>
  );
}

export default LocationMainContent;
