import { Box, Flex, IconButton, LinkBox, LinkOverlay, Text, useBreakpoint } from "@chakra-ui/react"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { storyblokEditable } from "@storyblok/react"
import Image from "next/legacy/image"
import { useContext, useEffect, useMemo, useState } from "react"
import { useInterval } from "../../../utils/hooks"
import { PartnerLandingContext } from "../../contexts/ppm365"
import { DynamicComponentProps } from "../../DynamicComponent"


export interface PartnerCarouselProps extends DynamicComponentProps {

}

const PartnerCarousel = ({ blok }: DynamicComponentProps): JSX.Element => {

    const partnerData = useContext(PartnerLandingContext)
    const [visiblePartners, setVisiblePartners] = useState<LandingPagePartner[]>([])
    const [displayNum, setDisplayNum] = useState(Number(blok.numItems))
    const [currentIndex, setCurrentIndex] = useState(0)
    const [nextDelay, setNextDelay] = useState(5000)
    const breakpoint = useBreakpoint({fallback: "lg" })

    const shuffledPartners = useMemo(() => {
        return partnerData.filter(p => (p.photo ? true : false ) && p.funded === false)
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)
    }, [partnerData])

    useEffect(() => {
        if (breakpoint === "base") {
            setDisplayNum(1)
        }
        else {
            setDisplayNum(Number(blok.numItems))
        }
    }, [breakpoint, blok.numItems])

    const getNextPartners = () => {
        let indexes = []

        let index = currentIndex + displayNum

        while (indexes.length < displayNum) {
           
            indexes = [...indexes, index]
            index++
        }

        setVisiblePartners(indexes.map(i => shuffledPartners[i % shuffledPartners.length]))
        setCurrentIndex(indexes[0])
    }

    const getPrevPartners = () => {
        let indexes = []

        let index = currentIndex - displayNum

        while (indexes.length < displayNum) {
            if (index < 0) {
                index = shuffledPartners.length - Math.abs(index)
            }
            indexes = [...indexes, index]
            index++
        }

        setVisiblePartners(indexes.map(i => shuffledPartners[i % shuffledPartners.length]))
        setCurrentIndex(indexes[0])

    }

    useEffect(() => {
        getNextPartners()
        // only want this to run once to load the initial
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayNum])

    useInterval(() => {
        if (shuffledPartners.length >= displayNum) {
            getNextPartners()
            setNextDelay(5000)
        }
    }, nextDelay)

    const nextClick = () => {
        getNextPartners()
        setNextDelay(30000)
    }

    const prevClick = () => {
        getPrevPartners()
        setNextDelay(30000)
    }

    return (
        <Flex 
        {...storyblokEditable(blok)} 
        h={`${blok.itemHeight}px`} 
        gap={5}>
            {
                visiblePartners.map((p, index) => {
                    return (
                        <Box
                            key={p.id}
                            position={"relative"}
                        >

                            <LinkBox h="full">

                                <LinkOverlay href={`/365/partners/${p.id}`}>
                                    <Box position="relative"

                                        w={{base: '90vw', md:`${blok.itemWidth}px` }}
                                        h={`${blok.itemHeight}px`}
                                        overflow={'hidden'}
                                        rounded={'xl'}
                                        boxShadow="dark-lg"
                                    >
                                        <Image
                                            src={p.photo}
                                            alt={`Image of ${p.pastorFirstName} ${p.pastorLastName}`}
                                            objectFit='cover'
                                            objectPosition={'center center'}
                                            layout="fill"

                                        />
                                        <Flex
                                            position="absolute"
                                            top="50%"
                                            left="50%"
                                            transform="translate(-50%, -50%)"
                                            padding="3"
                                            width="100%"
                                            height="100%"
                                            bgColor="blackAlpha.400"
                                            _hover={{
                                                bgColor: 'blackAlpha.600'
                                            }}
                                            rounded={'xl'}
                                            overflow="hidden"
                                            direction="column"
                                            justifyContent={"flex-end"}
                                        >
                                            <Flex
                                                direction="column"
                                                justifyContent="space-between"
                                                rounded={'xl'}
                                                overflow="hidden"
                                                width="100%"
                                                alignSelf={"flex-end"}
                                                padding="3"
                                                bgColor="whiteAlpha.700"
                                                transition="background-color 0.1s"
                                                _hover={{
                                                    bgColor: 'whiteAlpha.900'
                                                }}
                                            >

                                                <Box>
                                                    <Text
                                                        color="black"
                                                        textTransform="uppercase"
                                                        fontSize="lg"
                                                        fontWeight={"bold"}
                                                        marginBottom="0"
                                                    >
                                                        {p.pastorTitle} {p.pastorFirstName} {p.pastorLastName}
                                                    </Text>
                                                    <Text
                                                        color="black"
                                                        textTransform="uppercase"
                                                        fontSize="lg"
                                                        marginBottom="0"
                                                    >
                                                        {p.churchName}
                                                    </Text>
                                                    <Text
                                                        color="black"
                                                        textTransform="uppercase"
                                                        fontSize="lg"
                                                        marginBottom="0"
                                                    >
                                                        {p.location}
                                                    </Text>

                                                </Box>

                                            </Flex>

                                        </Flex>
                                    </Box>
                                </LinkOverlay>

                            </LinkBox>

                            {index === 0 &&
                                <Flex
                                    position={"absolute"}
                                    left="5px"
                                    top={0}
                                    h={"full"}
                                    alignItems={"center"}
                                >
                                    <IconButton
                                        aria-label="Previous Partner"
                                        icon={<FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>}
                                        onClick={prevClick}
                                    ></IconButton>
                                </Flex>
                            }
                            {index === displayNum - 1 &&
                                <Flex
                                    position={"absolute"}
                                    right="5px"
                                    top={0}
                                    h={"full"}
                                    alignItems={"center"}
                                >
                                    <IconButton
                                        aria-label="Next Partner"
                                        icon={<FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>}
                                        onClick={nextClick}
                                    ></IconButton>
                                </Flex>
                            }
                        </Box>
                    )
                })
            }
        </Flex>
    )
}

export default PartnerCarousel