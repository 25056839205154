import { Box, Text, Image, Link as ChakraLink, Button, Center } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import AddThis from "../misc/AddThis";
import Fancybox from "../misc/Fancybox";

interface JournalEntryProps extends JournalEntry {
  photoUrl: string;
  entryLink?: string;
  isEntryPage: boolean;
}

const JournalEntry = (props: JournalEntryProps):JSX.Element => {
  return (
    <Box id={`entry-${props.journalID}`} width={{ base: "95%", lg: "100%" }} >
        {
          props.entryLink ? 
            <Link href={props.entryLink} passHref legacyBehavior>
              <ChakraLink 
                color="brand.green.600"
                fontSize={{ base: "xl", lg: "3xl" }}  
              >
                {props.entrytitle}
              </ChakraLink>
            </Link>
          : <Text as="h3">{props.entrytitle}</Text>
        }

      <Text>by {props.author} | {props.journalDate}</Text>
      
      <div dangerouslySetInnerHTML={{ __html: props.entry }} style={{marginBottom: '15px'}}></div>
      
      {
        props.photoUrl !== '' && 
        <Fancybox options={{infinite: false}}>
          <Image
            cursor="pointer"
            data-fancybox="gallery"
            data-src={props.photoUrl}
            data-caption={`
              <b style="margin-bottom: 0px;">${props.entrytitle}</b><br />
              <em style="font-size: 12px;">by ${props.author}, ${props.journalDate}</em><br />
              ${props.entry.replaceAll('<p', '<p style="color:white;"')}`
            }
            src={props.photoUrl}
            alt={props.entrytitle}
            w="full"
            h="auto"
            borderRadius={'xl'}
          />
        </Fancybox>
      }
      
      <Center w="full" mt="5">
        {
          props.entryLink ? 
          <AddThis link={{ url: props.entryLink, title: props.entrytitle }} /> :
          <AddThis />
        }
      </Center>

      {
        props.isEntryPage && 
        <Center w="full" my="10">
          <Link href={`/mission-trip-journals/${props.tripID}`} passHref legacyBehavior>
            <Button
              as="a"
              sx={{ '&:hover': { textDecoration: 'none' } }}
              size={'lg'}
              colorScheme={'brand.green'}
              variant={'solid'}
              fontWeight={'normal'}
              lineHeight={0}
              width={'md'}
              leftIcon={<FontAwesomeIcon icon={['fas', 'book']} />}
              >
              View Full Trip Journal
            </Button>
          </Link>
        </Center>
      }
    </Box>
  );
}

export default JournalEntry;