// import { Flex } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/legacy/image";
import { useMemo } from "react";
import { getScaledPhotoSrc } from "../../utils/misc";

const ImageBlok = ({ blok }) => {
  const { photoUrl, blurUrl, dimensions } = useMemo(() => getScaledPhotoSrc(blok.image, Number(blok.width) ?? 1920), [blok.image, blok.width]);
  
  if (!blok.image?.filename || blok.image?.filename === '') return null;

  return (
      <Image
        {...storyblokEditable(blok)}
        src={photoUrl}
        blurDataURL={blurUrl}
        placeholder="blur"
        alt={blok.image?.alt}
        width={dimensions.width}
        height={dimensions.height}
        className="transition"
      />
  );
};

export default ImageBlok;
