import { Box, Flex, Grid, Text, useDisclosure, Link } from "@chakra-ui/react"
import { storyblokEditable } from "@storyblok/react"

import { useContext, useEffect, useState } from "react"
import { getUpdates } from "../../../utils/ppm365Api"
import { PartnerProfileContext, PartnerSupportStatusContext } from "../../contexts/ppm365"
import DynamicComponent, { DynamicComponentProps } from "../../DynamicComponent"
import Image from "next/legacy/image"
import PartnerUpdateModal from "./partnerUpdateModal"
import DateUtils from "../../../utils/dateUtils"

export interface PartnerSupporterUpdatesProps extends DynamicComponentProps {
    blok: any,
}

const PartnerSupporterUpdates = ({ blok, globalState, setGlobalState, locationState, ppm365 }: PartnerSupporterUpdatesProps): JSX.Element => {

    const supportStatus = useContext(PartnerSupportStatusContext)
    const profile = useContext(PartnerProfileContext)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [updates, setUpdates] = useState<PartnerUpdate[]>([])
    const [modalUpdate, setModalUpdate] = useState<PartnerUpdate>(null)
    const totalColumns = blok.columns ? blok.columns : 3



    useEffect(() => {
        if (profile && supportStatus?.isSupporter) {
            const getUpdateCall = async () => {
                const updates = await getUpdates(profile.id)

                const sortedUpdates = updates.sort((a, b) => {
                    return DateUtils.parseServerDate(b.entrydate, "local").valueOf() - DateUtils.parseServerDate(a.entrydate, "local").valueOf()
                })

                setUpdates(sortedUpdates)
            }

            getUpdateCall()

        }
    }, [profile, supportStatus?.isSupporter])

    const showUpdateModal = (update: PartnerUpdate) => {
        setModalUpdate(update)
        onOpen()
    }



    return (
        <>
            <Box {...storyblokEditable(blok)}>
                <Grid
                    templateColumns={{base: '1fr', sm: `repeat(${totalColumns}, 1fr)`}} gap={3}
                >
                    {
                        supportStatus && supportStatus.isSupporter && updates.length === 0 &&
                        <Text>Check back soon for updates!</Text>
                    }
                    {
                        updates.map(u => {
                            return (
                                <Flex
                                    key={u.id}
                                    direction={"column"}
                                    alignItems={"flex-start"}
                                    justifyContent={"space-between"}
                                    cursor={"pointer"}
                                    padding={1}
                                    borderRadius={'xl'}

                                    onClick={() => showUpdateModal(u)}
                                    _hover={{
                                        backgroundColor: "blackAlpha.200",
                                        
                                        
                                    }}
                                >
                                    <Flex direction={"column"}>
                                        <Box
                                            width={"200px"}
                                            height={"120px"}
                                            position="relative"
                                            overflow={"hidden"}
                                            rounded={"lg"}
                                            minW={"200px"}
                                        >
                                            {
                                            u.photo === 1 && <Image
                                                src={ u.photoUrl }
                                                alt={u.entrytitle}
                                                width={200}
                                                height={120}
                                                objectFit="cover"
                                        
                                            />
                                            }
                                        </Box>
                                        <Text alignSelf={"flex-start"} fontSize={"lg"} fontWeight={"semibold"}>{u.entrytitle} </Text>
                                        <Text noOfLines={3} dangerouslySetInnerHTML={{ __html: u.entry }} style={{ maxHeight: "100px", textOverflow: "ellipses" }}></Text>
                                    </Flex>
                                    <Link fontWeight={"bold"} alignSelf="flex-end" onClick={() => showUpdateModal(u)}>Read More {'>'}</Link>
                                </Flex>)
                        })
                    }
                </Grid>
                {
                    supportStatus && !supportStatus?.isSupporter && blok.nonSupporterContent?.map((nestedBlok, index) => (
                        <DynamicComponent
                            blok={nestedBlok}
                            key={index}
                            globalState={globalState}
                            setGlobalState={setGlobalState}
                            locationState={locationState}
                            ppm365={ppm365}
                        />
                    ))
                }

            </Box>
            <PartnerUpdateModal update={modalUpdate} isOpen={isOpen} onClose={onClose}></PartnerUpdateModal>
        </>

    )
}

export default PartnerSupporterUpdates