import { Flex, Select, Box, Text, Checkbox } from "@chakra-ui/react"
import { storyblokEditable } from "@storyblok/react"
import Image from "next/image"
import Link from "next/link"
import { useContext, useMemo, useState } from "react"
import { PartnerLandingContext } from "../../contexts/ppm365"

export interface PartnerListProps {
    blok: any
    globalState: GlobalState
    height: string
}

const PartnerList = ({ blok, globalState }: PartnerListProps): JSX.Element => {

    const partnerData = useContext(PartnerLandingContext)


    const locations = useMemo(() => {
        return globalState.ppm365Locations.filter(l => partnerData.find(p => p.locationId === l.rootsId)).sort((a, b) => a.name.replace("The ", "").localeCompare(b.name.replace("The ", "")))
    }, [globalState.ppm365Locations, partnerData])

    const [locationFilter, setLocationFilter] = useState('')
    const [fundingFilter, setFundingFilter] = useState(false)
    const filteredPartners = useMemo(() => {

        return partnerData.filter(p => {
            return (
                !locationFilter ||
                p.locationId === locationFilter ||
                (locationFilter === "d17f11920ce741d4a427fc43e86239d4" && p.usaLocation)
            ) &&
                (!fundingFilter || (fundingFilter && p.funded === false))
        })
            .sort((a, b) => (a.pastorFirstName + a.pastorLastName).localeCompare(b.pastorFirstName + b.pastorLastName))
            .sort((a, b) => a.location.localeCompare(b.location))


    }, [partnerData, locationFilter, fundingFilter])

    return (
        <Flex
            {...storyblokEditable(blok)}
            direction="column"
            gap="3"
            w={"full"}
            h={`${blok.height}px`}
        >
            <Select
                placeholder="Filter by Location"
                onChange={(e) => { setLocationFilter(e.target.value) }}>
                <option value="">All Locations</option>
                {locations.map(l => {
                    return <option key={l.rootsId} value={l.rootsId}>{l.name}</option>
                })}
            </Select>
            <Checkbox
            colorScheme="brand.green"
            onChange={(e) => {setFundingFilter(e.target.checked)}}
            >Only Partners Needing a Sponsor</Checkbox>
            {/* <Input
                  placeholder="Search..."
                  layerStyle={'lightForm'}
                  onChange={(e) => {
                    const search = e.target.value.toLowerCase();
                    
                    // setFilteredJournals(props.currentJournals.filter(journal => {
                    //   return journal.groupname.toLowerCase().includes(search)
                    //     || journal.locationName.toLowerCase().includes(search)
                    //     || journal.homeTown.toLowerCase().includes(search)
                    // }));
                  }}
                /> */}
            <Box
                borderColor={'brand.green.600'}
                borderWidth={'1px'}
                borderRadius="lg"
                flexGrow="1"
                h="full"
                overflowY={'auto'}
                bgColor="blackAlpha.50"
            >
                {
                    filteredPartners.length === 0 && <Text fontWeight={'bold'} textAlign='center' py="5">No results.</Text>
                }

                {
                    filteredPartners.map((partner, index) => (
                        <Flex key={partner.id} gap={1} padding={1} >
                            <Box rounded={"md"} overflow={"hidden"} width={50} height={67} >

                                {partner.photo ?
                                    <Image
                                        style={{ objectFit: 'cover', objectPosition: "center top", width: "50px", height: "67px" }}
                                        src={partner.photo}
                                        alt={`Photo of ${partner.pastorFirstName} ${partner.pastorLastName}`}
                                        width={50}
                                        height={67}
                                    ></Image>
                                    : //no photo
                                    <Box w={50} h={67}></Box>
                                }

                            </Box>
                            <Flex flexGrow={1}

                                borderBottomWidth={index === filteredPartners.length - 1 ? "0px" : "1px"}
                                borderBottomColor="brand.darkgray.200"
                                justifyContent={'flex-start'}
                                alignItems={'flex-start'}
                                direction="column"
                                p="2"
                            >
                                <Link href={`/365/partners/${partner.id}`} passHref legacyBehavior>
                                    <Text
                                        as="a"
                                        textAlign="left"
                                        mb="0"
                                        fontWeight={'bold'}
                                        lineHeight={'1'}
                                    >
                                        {partner.pastorFirstName + ' ' + partner.pastorLastName}
                                    </Text>
                                </Link>
                                {
                                    partner.churchName.replace(', ', '').trim() !== '' &&
                                    <Text textAlign="left" mb="0" lineHeight={'1'} fontWeight={'bold'}><small>{partner.churchName}</small></Text>
                                }
                                <Text textAlign="left" mb="0" lineHeight={'1'} textTransform={'uppercase'}><em><small>{partner.location}</small></em></Text>
                            </Flex>
                        </Flex>
                    ))
                }
            </Box>
        </Flex>
    );
}

export default PartnerList