import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { storyblokEditable } from "@storyblok/react";
import { Box, Button, Container, Flex, FormControl, FormLabel, Grid, GridItem, Select, SimpleGrid, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm, UseFormRegister, useWatch } from "react-hook-form";
import { TableCardContent } from "../layout/tables/Tables";
import { getTripTimeframes } from "../../utils/misc";
import RequestInfoModal from "../misc/contact-form/RequestInfoModal";
import Link from "next/link";
import { tripLocationSearch } from "../../utils/ppmApi";

interface LocationTripSearchComponentProps {
  blok: any;
  globalState: GlobalState;
  locationState: RootsLocation;
  setGlobalState: Dispatch<SetStateAction<GlobalState>>;
}

const LocationTripSearch = ({ blok, globalState, locationState, setGlobalState }: LocationTripSearchComponentProps): JSX.Element => {
  const [searchResults, setSearchResults] = useState<TripSearchResult[]>([]);
  const [resultsLoading, setResultsLoading] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);

  return (
    locationState &&
    <>
      <Box
        {...storyblokEditable(blok)}
        padding="0"
        sx={{
          background: {
            base: 'transparent',
            md: 'linear-gradient(90deg, var(--chakra-colors-brand-darkgray-600) 50%, var(--chakra-colors-brand-green-600) 50%)'
          }
        }}
      >
        <Container
          maxW="container.xl"
          sx={{
            background: 'transparent',
          }}
          paddingX={{ base: 0, md: '1rem' }}
        >
          <Grid templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(12, 1fr)' }}>
            <GridItem colSpan={4} bg='brand.darkgray.600' height="100%" p="10">
              <Flex
                alignItems={{ base: 'center', md: 'flex-end' }}
                justifyContent={'center'}
                textAlign={{ base: 'center', md: 'right' }}
                direction="column"
                height="100%"
              >
                <Text
                  fontSize={{ base: '2xl', md: '3xl' }}
                  color='brand.green.600'
                  textTransform={'uppercase'}
                  marginBottom="0"
                  lineHeight="1"
                  as="h3"
                  textAlign={{ base: 'center', md: 'right' }}
                >
                  {locationState?.name}
                </Text>

                <Text
                  fontSize={{ base: '2xl', md: '3xl' }}
                  color='white'
                  textTransform={'uppercase'}
                  marginBottom="0"
                  lineHeight="1"
                  as="h3"
                  textAlign={{ base: 'center', md: 'right' }}
                >
                  <strong>Trip Search</strong>
                </Text>
              </Flex>
            </GridItem>

            <GridItem
              colSpan={8}
              bg='brand.green.600'
              color='white'
              px="5"
            >
              <SearchForm
                locationState={locationState}
                setResultsLoading={setResultsLoading}
                setSearchResults={setSearchResults}
                submitError={submitError}
                setSubmitError={setSubmitError}
              />
            </GridItem>

          </Grid>
        </Container>
      </Box>

      <Box>
        <Container
          maxW="container.xl"
          borderWidth={1}
          borderColor="brand.green.600"
          borderRadius={'xl'}
          boxShadow="xl"
        >
          <Text
            as="h4"
            fontSize="2xl"
            fontWeight={'bold'}
            textAlign={'center'}
            textTransform="uppercase"
            my="5"
          >
            Trip Search Results
          </Text>

          <SearchResults results={searchResults} loading={resultsLoading} globalState={globalState} setGlobalState={setGlobalState} submitError={submitError} />
        </Container>
      </Box>
    </>
  );
}

interface SelectFormProps {
  name: 'cost' | 'timing' | 'location';
  label: string;
  options: { value: string, label: string }[];
  disabled?: boolean;
  register: UseFormRegister<LocationTripSearchProps>
}

interface SearchFormProps {
  locationState: { id: string, name: string, tripCosts: number[] };
  setResultsLoading: Dispatch<SetStateAction<boolean>>;
  setSearchResults: Dispatch<SetStateAction<TripSearchResult[]>>;
  submitError: boolean;
  setSubmitError: Dispatch<SetStateAction<boolean>>;
}

const SearchForm = ({ locationState, setSearchResults, setResultsLoading, submitError, setSubmitError }: SearchFormProps): JSX.Element => {
  const { register, reset, handleSubmit, getValues, setValue, formState, control } = useForm<LocationTripSearchProps>({
    defaultValues: {
      cost: 'all',
      timing: 'all',
      location: locationState?.id
    }
  });

  useEffect(() => {
    setValue('cost', 'all');
    setValue('timing', 'all');
    setValue('location', locationState?.id);
    getSearchResults(getValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState?.id]);

  const getSearchResults = async (values) => {
    setResultsLoading(true);
    setSubmitError(false);

    const submitValues = JSON.parse(JSON.stringify(values));

    try {
      const data = await tripLocationSearch(values);

      setSearchResults(data.tripList);
    } catch (e) {
      console.error(e);
      setSubmitError(true);

    }

    setResultsLoading(false);
  }

  const cost = useWatch({ control, name: 'cost' })
  const timing = useWatch({ control, name: 'timing' })

  useEffect(() => {
    if (timing && cost) {
      getSearchResults(getValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timing, cost, handleSubmit]);

  const clearForm = () => {
    reset();
  }

  return (
    <form onSubmit={handleSubmit(getSearchResults)}>
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(4, 1fr)' }}
        justifyContent="center"
        alignItems="center"
        height="full"
        gap="5"
        paddingY="10"
      >
        <GridItem colSpan={1}>
          <SearchFormSelect
            name="location"
            label="Location"
            options={[{ value: locationState?.id, label: locationState?.name },]}
            disabled={true}
            register={register}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <SearchFormSelect
            name="timing"
            label="Timeframe"
            options={[{ value: 'all', label: 'Filter dates...' }, ...getTripTimeframes()]}
            disabled={formState.isSubmitting}
            register={register}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <SearchFormSelect
            name="cost"
            label="Cost"
            options={[{ value: 'all', label: 'Filter prices...' }, ...locationState?.tripCosts.map(cost => ({ value: cost.toString(), label: `$${cost}` }))]}
            disabled={formState.isSubmitting}
            register={register}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Button
            mt={{ base: 3, lg: 7 }}
            w={"full"}
            rounded="lg"
            colorScheme={'brand.darkgray'}
            variant="solid"
            size="sm"
            disabled={formState.isSubmitting}
            onClick={clearForm}
          >
            CLEAR SEARCH
          </Button>
        </GridItem>
      </Grid>
    </form>
  )
}

const SearchFormSelect = ({ name, label, options, disabled, register }: SelectFormProps): JSX.Element => {
  return (
    <FormControl>
      <FormLabel
        htmlFor={name}
        color={'white'}
        textAlign={'left'}
        mb={0}
        mt={'6px'}
        fontWeight={'bold'}
        fontSize="sm"
      >
        {label}
      </FormLabel>
      <Select
        name={name}
        size="sm"
        rounded="lg"
        disabled={disabled}
        variant="outline"
        borderColor="white"
        {...register(name, { required: true })}
        _disabled={{
          color: 'white',
          cursor: 'not-allowed',
        }}
        _hover={{}}
        sx={{
          _focusVisible: {
            borderColor: 'brand.orange.600',
            boxShadow: '0 0 0 1px var(--chakra-colors-brand-orange-600)',
          }
        }}
      >
        {
          options.map((option) =>
            <option key={option.value} value={option.value} style={{ color: 'black' }}>{option.label}</option>
          )
        }
      </Select>
    </FormControl>
  )
}

interface SearchResultProps {
  results: TripSearchResult[];
  loading: boolean;
  globalState: GlobalState;
  setGlobalState: Dispatch<SetStateAction<GlobalState>>;
  submitError: boolean;
}

const SearchResults = ({ results, loading, globalState, submitError, setGlobalState }: SearchResultProps): JSX.Element => {
  // info request modal stuff
  const infoRequestModal = useDisclosure();
  const [infoRequestTrip, setInfoRequestTrip] = useState<TripSearchResult | null>(null);

  const openRequestInfoModal = (trip: TripSearchResult) => {
    setInfoRequestTrip(trip);
    infoRequestModal.onOpen();
  }

  return <>
    <RequestInfoModal
      onClose={infoRequestModal.onClose}
      isOpen={infoRequestModal.isOpen}
      trip={infoRequestTrip}
      globalState={globalState}
      setGlobalState={setGlobalState}
    />
    {
      submitError &&
      <Flex direction="column" gap="5" justifyContent="center" alignItems="center">
        <Text as="p" fontWeight="bold" mb="5" color="red.600">Error loeading search results.</Text>
      </Flex>
    }

    {
      loading ?
        <Flex direction="column" gap="5" justifyContent="center" alignItems="center">
          <Spinner size="xl" color="brand.green.600" emptyColor="brand.green.100" />
          <Text as="p" fontWeight="bold" mb="5" color="brand.green.600">Loading results...</Text>
        </Flex>

          : results && results.length === 0 ? (
            <>
              <Text as="p" textAlign={'center'}>No trips were found with the given location, timeframe, and cost.</Text>
              <Text as="p" textAlign={'center'} mb="5">Adjust your search parameters and try again.</Text>
            </>
          ) : (
            <TableContainer>
              <Table className="cards">
                <Thead>
                  <Tr>
                    <Th
                      fontWeight="bold"
                    >
                      <Text as="p" mb="0" fontSize="md" textTransform="capitalize">Location</Text>
                    </Th>
                    <Th
                      textAlign="center"
                      fontWeight="bold"
                    >
                      <Text as="p" mb="0" fontSize="md" textTransform="capitalize">Trip Date</Text>
                    </Th>
                    <Th
                      textAlign="center"
                      fontWeight="bold"
                    >
                      <Text as="p" mb="0" fontSize="md" textTransform="capitalize">Airport</Text>
                    </Th>
                    <Th
                      textAlign="center"
                      fontWeight="bold"
                    >
                      <Text as="p" mb="0" fontSize="md" textTransform="capitalize">Duration</Text>
                    </Th>
                    <Th
                      textAlign="center"
                      fontWeight="bold"
                    >
                      <Text as="p" mb="0" fontSize="md" textTransform="capitalize">Cost</Text>
                    </Th>
                    <Th minW="20">&nbsp;</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    results?.map((result, index) => (
                      <Tr key={index}>
                        <Td fontWeight="bold">
                          <TableCardContent label="Location" body={result.theLocation} />
                        </Td>
                        <Td textAlign="center">
                          <TableCardContent label="Trip Date" body={result.tripdate} />
                        </Td>
                        <Td textAlign="center">
                          <TableCardContent label="Airport" body={result.airport} />
                        </Td>
                        <Td textAlign="center">
                          <TableCardContent label="Duration" body={`${result.duration}D/${result.duration - 1}N`} />
                        </Td>
                        <Td textAlign="center">
                          {result.reserved === 0
                            ? <TableCardContent label="Cost" body={`$${result.cost}`} />
                            : <TableCardContent label="Cost" body={``} />
                          }
                        </Td>
                        {
                          result.reserved === 0 ?
                            result.limitedAvailability === 0 || result.limitedAvailability === undefined ?
                              (
                                <Td minW="30">
                                  <Flex direction={{ base: 'column', lg: 'row' }} gap="5">
                                    <Link href={`https://register.ppm.org/${result.hash.replace('A', '')}`} passHref >
                                      <Button
                                        as="a"
                                        sx={{ '&:hover': { textDecoration: 'none' } }}
                                        target="_blank"
                                        w="full"
                                        rounded="lg"
                                        colorScheme={'brand.darkgray'}
                                        variant="outline"
                                        size="sm"
                                        leftIcon={<FontAwesomeIcon icon={['fas', 'edit']} />}
                                      >
                                        REGISTER GROUP
                                      </Button>
                                    </Link>
                                    <Button
                                      w="full"
                                      rounded="lg"
                                      colorScheme={'brand.orange'}
                                      variant="solid"
                                      size="sm"
                                      leftIcon={<FontAwesomeIcon icon={['fas', 'message']} />}
                                      onClick={() => {
                                        openRequestInfoModal(result);
                                      }}
                                    >
                                      REQUEST INFO
                                    </Button>
                                  </Flex>
                                </Td>
                              ) : (//limited availability


                              <Td>
                                <Flex direction="row" gap="3" justifyContent={'center'} alignItems={'center'}>
                                  <Button
                                    size="sm"
                                    colorScheme="brand.orange"
                                    flexGrow={{ base: 0, xl: 1 }}
                                    w={{ base: 'full', xl: 'auto' }}
                                    leftIcon={<FontAwesomeIcon icon={['fas', 'message']} />}
                                    onClick={() => {
                                      openRequestInfoModal(result);
                                    }}
                                  >
                                    Limited Availability - Request Info
                                  </Button>
                                </Flex>
                              </Td>
                            )

                          : (//reserved
                            <Td>
                              <Button
                                w="full"
                                rounded="lg"
                                colorScheme={'brand.darkgray'}
                                variant="outline"
                                size="sm"
                                disabled={true}
                              >
                                RESERVED
                              </Button>
                            </Td>
                          )
                      }

                    </Tr>
                  ))
                }
              </Tbody>

            </Table>
          </TableContainer>
        )
    }
  </>;
}

export default LocationTripSearch;
