import { Box, Button, Center, Checkbox, Container, Flex, FormControl, FormLabel, Radio, RadioGroup, Select, Stack, VisuallyHidden, Text, Input, Slide, SlideFade, Collapse, Stepper, useSteps, StepIndicator, StepStatus, StepIcon, StepNumber, StepTitle, StepDescription, Step, StepSeparator, Textarea } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AddressElement, CardElement, Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe, PaymentIntentResult, Stripe, StripeAddressElementChangeEvent, StripeElements, StripePaymentElementChangeEvent } from "@stripe/stripe-js"
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import Image from "next/image"
import { Decimal } from "decimal.js"
import { feeAmountCoverage, getPaymentIntentV2, getRecurringPaymentIntent, getStripe } from "../../utils/stripe"
import * as changeCase from "change-case"



export interface DonationFormProps {
    blok: DonationBlok,
    globalState: GlobalState
}


const DonationForm = (props: DonationFormProps): JSX.Element => {
    return <Elements stripe={getStripe()}>
        <DonationFormWithElements {...props}></DonationFormWithElements>
    </Elements>
}

const steps = [
    { title: "Donation", description: "Details" },
    { title: "Billing", description: "Details" },

]

const DonationFormWithElements = ({ blok, globalState }: DonationFormProps): JSX.Element => {

    const [email, setEmail] = useState<string>('');
    const [selectedFund, setSelectedFund] = useState<string>('')
    const [selectedAmount, setSelectedAmount] = useState<AmountBlok | null>(null)
    const [selectedItemKey, setSelectedItemKey] = useState<string>('')
    const [customAmount, setCustomAmount] = useState<Decimal>(new Decimal(0))
    const [customAmountString, setCustomAmountString] = useState<string>('')

    const [enterPaymentClicked, setEnterPaymentClicked] = useState<boolean>(false)
    const [recurring, setRecurring] = useState(false)
    const [userRecurringChoice, setUserRecurringChoice] = useState(false)
    const [feeAmount, setFeeAmount] = useState(0)
    const [coverFees, setCoverFees] = useState(false)
    const [paymentComplete, setPaymentComplete] = useState(false)
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    })
    const [customFieldValues, setCustomFieldValues] = useState<any>({})

    const myRef = useRef(null)

    const funds: DonationFundBlok[] = useMemo(() => {
        if (selectedAmount && selectedAmount.requiredFunds && selectedAmount.requiredFunds.length > 0) {
            const requiredFunds = selectedAmount.requiredFunds.map(f => f)

            return blok.funds.filter(f => requiredFunds.includes(f.fund))
        }
        else {

            return blok.funds.filter(f => !f.onlyShowWhenRequired)
        }
    }, [blok.funds, selectedAmount])



    const totalAmount = useMemo(() => {

        let total = 0

        if (selectedAmount) {
            total = selectedAmount.custom ? customAmount.times(100).toNumber() : new Decimal(selectedAmount.value).times(100).toNumber()
            if (coverFees) {
                total += feeAmount
            }
        }
        return total

    }, [feeAmount, coverFees, customAmount, selectedAmount])


    const amountChange = (nextValue: string) => {
        const amount = blok.amounts.find(a => a._uid === nextValue)
        setSelectedAmount(amount ?? null)
        //checkRequiredFunds(amount)
    }

    const customAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCustomAmount(new Decimal(event.target.value))
        setCustomAmountString(event.target.value)
    }


    const coverFeesChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCoverFees(event.target.checked)

    }

    const backToPayment = () => {
        setActiveStep(0)
    }

    useEffect(() => {

        if (!funds.find(f => f.fund === selectedFund)) {
            setSelectedFund('')
        }
    }, [selectedFund, funds])

    useEffect(() => {
        if (funds && funds.length === 1) {
            setSelectedFund(funds[0].fund)
        }
    }, [funds])

    useEffect(() => {
        if (blok.amounts && blok.amounts.length === 1) {
            setSelectedAmount(blok.amounts[0])
        }
    }, [blok.amounts])

    useEffect(() => {
        if (selectedAmount?.recurringOnly) {
            setRecurring(true)
        }
        else {
            setRecurring(userRecurringChoice)
        }
    }, [selectedAmount, userRecurringChoice])


    useEffect(() => {

        if (selectedAmount) {
            setSelectedItemKey(selectedAmount?.itemKey ?? '')
            if (selectedAmount.custom) {
                setFeeAmount(feeAmountCoverage(customAmount.times(100).toNumber()))
            }
            else {
                setFeeAmount(feeAmountCoverage(new Decimal(selectedAmount.value).times(100).toNumber()))
            }
        }

    }, [customAmount, selectedAmount, blok.amounts])

    const resetForm = () => {
        setEmail('')
        setSelectedAmount(null)
        setSelectedFund('')
        setSelectedItemKey('')
        setRecurring(false)
        setEnterPaymentClicked(false)
    }

    const handlePaymentComplete = () => {
        resetForm()
        setPaymentComplete(true)
        setActiveStep(2);
    }

    const enterPaymentDisabled = () => {
        return email === '' || selectedFund === '' || selectedAmount === null
    }

    const onEnterPaymentClick = () => {
        setEnterPaymentClicked(true)
        setActiveStep(1)
    }

    const stepClickEnabled = (index: number) => {
        return (index === 0 && activeStep !== 0) ||
            (index === 1 && activeStep !== 1 && !enterPaymentDisabled())
    }

    const onStepClick = (index: number) => {
        switch (index) {
            case 0:
                backToPayment()
                break
            case 1:
                onEnterPaymentClick()
        }
    }

    return <Flex ref={myRef} direction="column" gap="5" scrollMarginTop={"px"} scrollSnapAlign="start">

        {paymentComplete && <Text>Thank you for your donation! Check your email for a copy of your donation receipt.</Text>}

        {!paymentComplete && <>
            <Stepper index={activeStep} hidden={!enterPaymentClicked} colorScheme="brand.green">
                {steps.map((step, index) => (
                    <Step key={index} onClick={() => stepClickEnabled(index) ? onStepClick(index) : false} style={stepClickEnabled(index) ? { cursor: "pointer" } : {}} >
                        <StepIndicator>
                            <StepStatus
                                complete={<StepIcon />}
                                incomplete={<StepNumber />}
                                active={<StepNumber />}
                            />
                        </StepIndicator>

                        <Box flexShrink='0'>
                            <StepTitle>{step.title}</StepTitle>
                            <StepDescription>{step.description}</StepDescription>
                        </Box>

                        <StepSeparator />
                    </Step>
                ))

                }
            </Stepper>
            <Flex hidden={activeStep !== 0} direction="column" gap={5}>
                <Flex direction="column" gap="5" borderRadius="lg" borderColor="brand.darkgray.200" borderWidth="1px" padding="2">
                    <FormControl isRequired >
                        <FormLabel htmlFor="amount" fontWeight={"semibold"}>Donation Amount</FormLabel>
                        <RadioGroup name="amount" value={selectedAmount?._uid} onChange={amountChange}>
                            <Stack direction="column">
                                {
                                    blok.amounts && blok.amounts.map((a) => {
                                        if (a.custom) {
                                            return <Stack key={a._uid} direction="row"><Radio value={a._uid}>{blok.amounts.length === 1 ? 'Amount' : 'Other'}</Radio><Input isDisabled={!selectedAmount?.custom || enterPaymentClicked} type="number" value={customAmountString} onChange={customAmountChange}></Input></Stack>
                                        }
                                        else {
                                            const decimalAmount = new Decimal(a.value)
                                            return <Radio key={a._uid} value={a._uid}><Text as="b">${decimalAmount.toFixed(2)}</Text> {a.description ? `- ${a.description}` : ''}</Radio>
                                        }
                                    })
                                }
                            </Stack>
                        </RadioGroup>
                    </FormControl>
                    <FormControl >
                        <VisuallyHidden>
                            <FormLabel htmlFor="coverFees">Help cover our transaction fees</FormLabel>
                        </VisuallyHidden>
                        <Checkbox
                            name="coverFees"
                            isChecked={coverFees}
                            onChange={coverFeesChange}
                        >Add {(feeAmount / 100).toFixed(2)} to help cover our transaction fees</Checkbox>
                    </FormControl>
                </Flex>
                <Flex direction="column" gap="5" borderRadius="lg" borderColor="brand.darkgray.200" borderWidth="1px" padding="2">
                    {funds && funds.length > 1 &&
                        <FormControl isRequired >
                            <FormLabel htmlFor="fund" fontWeight={"semibold"}>My donation is for</FormLabel>
                            <Select
                                name="fund"
                                isDisabled={(funds && funds.length === 1)}
                                placeholder="Select"
                                value={selectedFund}
                                onChange={(e) => setSelectedFund(e.target.value)}
                                isRequired>
                                {funds && funds.map(fund => {
                                    return <option key={fund.fund} value={fund.fund}>{fund.displayName ? fund.displayName : fund.fund}</option>
                                })}
                            </Select>
                        </FormControl>
                    }
                    {(selectedAmount && selectedAmount.recurringOnly) ?
                        <>
                            Thank you for showing your support with a monthly recurring donation!
                        </>

                        : //no amount or not recurring only

                        <FormControl>
                            <VisuallyHidden>
                                <FormLabel htmlFor="recurring">Show my support by making this a recurring donation</FormLabel>
                            </VisuallyHidden>
                            <Checkbox
                                name="recurring"
                                isChecked={recurring}
                                onChange={(e) => setUserRecurringChoice(e.target.checked)}
                            >Show my support by making this a recurring donation</Checkbox>
                        </FormControl>
                    }
                </Flex>
                <Flex direction="column" gap="5" borderRadius="lg" borderColor="brand.darkgray.200" borderWidth="1px" padding="2">
                    <FormControl isRequired>
                        <FormLabel htmlFor="email" fontWeight={"semibold"}>Email</FormLabel>
                        <Input name="email" value={email} placeholder="Email Address" onChange={(e) => setEmail(e.target.value)}></Input>
                    </FormControl>
                    {
                        blok.customFields && blok.customFields.map(cf => {
                            return <FormControl key={cf.name}>
                                <FormLabel htmlFor={`cf_${cf.name}`} fontWeight={"semibold"}>{cf.displayText}</FormLabel>
                                <Input name={`cf_${cf.name}`} value={customFieldValues[cf.name]} onChange={(e) => setCustomFieldValues({ ...customFieldValues, [changeCase.snakeCase(cf.name)]: e.target.value })}></Input>
                            </FormControl>
                        })
                    }
                </Flex>
            </Flex>

            {/* THis looks a little weird, but the stripe Elements provider requires the amount to be set when the stripe prop is set, and you can't use useElements
without the compnent being wrapped in an Elements provider.  So I need to lock in the amount before creating the Elements provider.  Maybe there's a better way
to do this */}

            {activeStep === 0 && <Button colorScheme="brand.green" isDisabled={enterPaymentDisabled()} onClick={e => { onEnterPaymentClick() }}>Enter Payment Information</Button>}

            {activeStep === 1 &&
                <Elements stripe={getStripe()} options={{ mode: recurring ? "subscription" : "payment", amount: totalAmount, currency: "usd", setup_future_usage: recurring ? "off_session" : null }}>
                    <SripeElements
                        email={email}
                        amount={totalAmount}
                        recurring={recurring}
                        description={selectedFund}
                        onPaymentComplete={handlePaymentComplete}
                        metadata={{ donationType: blok.type, donationFund: selectedFund, itemKey: selectedItemKey, source: "donation" }}
                        customFieldValues={customFieldValues}
                        showComment={true} ></SripeElements>
                </Elements>
            }

        </>}
    </Flex>



}


const SripeElements = ({ email, amount, description, recurring, onPaymentComplete, metadata, customFieldValues, showComment }: { email: string, amount: number, description: string, recurring: boolean, onPaymentComplete: () => void, metadata?: any, customFieldValues?: any, showComment: boolean }): JSX.Element => {

    const stripe = useStripe();
    const elements = useElements();

    const [submitting, setSubmitting] = useState(false)
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [cardReady, setCardReady] = useState(false);
    const [addressReady, setAddressReady] = useState(false)
    const [address, setAddress] = useState<StripeAddressElementChangeEvent["value"]>()
    const [submitReady, setSubmitReady] = useState(false)
    const [comment, setComment] = useState('');


    const confirmCardPayment = async (stripe: Stripe, elements: StripeElements) => {

        setSubmitting(true);

        const submitResponse = await elements.submit()

        if (submitResponse.error) {
            setErrorMsg(submitResponse.error.message)
            setSubmitting(false)
        }
        else {
            let pi: { clientSecret: string }



            try {
                if (recurring === false) {
                    pi = await getPaymentIntentV2(amount, description, metadata, { ...customFieldValues, comment: comment })
                }
                else {
                    pi = await getRecurringPaymentIntent(amount, description, email, address, metadata, { ...customFieldValues, comment: comment })
                }
            }
            catch (err) {
                TrackJS.track(err)
                setErrorMsg("There was a problem processing your donation.  Your card has not been charged.  Please refresh the page and try again");
            }
            // const cardElement = elements.getElement(CardElement);
            const result = await stripe.confirmPayment({
                clientSecret: pi.clientSecret,
                elements: elements,
                redirect: "if_required",
                confirmParams: {
                    return_url: window.location.href,
                    payment_method_data: {
                        billing_details: { email: email }
                    }
                }
            })

            if (result.error) {
                setErrorMsg(result.error.message)
            }
            else {
                resetForm()

            }
            setSubmitting(false)

        }

    }

    const resetForm = () => {

        elements.getElement("address").clear()
        elements.getElement("payment").clear()
        onPaymentComplete()

    }

    const onCardElementChange = (event: StripePaymentElementChangeEvent) => {
        if (event.complete) {
            setCardReady(true)
            if (addressReady) {
                setSubmitReady(true)
            }
        }
        else {
            setCardReady(false)
            setSubmitReady(false)
        }
    }

    const onAddressChange = (event: StripeAddressElementChangeEvent) => {
        if (event.complete) {
            setAddressReady(true)
            setAddress(event.value)
            if (cardReady) {
                setSubmitReady(true)
            }
        }
        else {

            setAddressReady(false)
            setSubmitReady(false)
        }
    }


    return (
        <>
            <Flex direction="column" gap={3}>


                <Text fontWeight={"bold"}>Billing Details</Text>

                <Box
                    borderColor="brand.darkgray.200"
                    borderWidth="1px"
                    borderRadius="lg"
                    padding="3">
                    <AddressElement
                        options={
                            {
                                fields: { phone: "always" },
                                validation: { phone: { required: "auto" } },
                                mode: "billing",
                                display: { name: "split" }

                            }} onChange={onAddressChange}></AddressElement>
                </Box>
                <Box
                    borderColor="brand.darkgray.200"
                    borderWidth="1px"
                    borderRadius="lg"
                    padding="3">
                    <PaymentElement onChange={onCardElementChange} options={{ fields: { billingDetails: { email: "never" } } }}></PaymentElement>
                    {/* <CardElement options={{ hidePostalCode: true }} onChange={onCardElementChange} /> */}
                    {showComment &&
                        <FormControl>
                            <FormLabel htmlFor="donationComment">Comment</FormLabel>
                            <Textarea name="donationComment" value={comment} onChange={(e) => setComment(e.target.value)}></Textarea>
                        </FormControl>
                    }
                </Box>

                {errorMsg !== '' && <Text as="p" textColor="red.600" textAlign={'center'}>{errorMsg}</Text>}

            </Flex>


            <Flex my="5" gap="3" flexDirection="column" alignItems="center" justifyContent="flex-end">
                <Button
                    onClick={() => confirmCardPayment(stripe, elements)}
                    isDisabled={submitting || !submitReady}
                    colorScheme="brand.green"
                    leftIcon={submitting ? <FontAwesomeIcon icon={["fas", "circle-notch"]} className="fa-spin"></FontAwesomeIcon> : undefined}
                >
                    Donate ${(amount / 100).toFixed(2)} {recurring ? "Monthly" : ""}
                </Button>
                <Text mb="0" fontWeight={"semibold"}>to {description}</Text>
                <Image
                    src="/images/powered-by-stripe.png"
                    alt="Powered by Stripe"
                    width={119}
                    height={26}
                />
            </Flex>

        </>
    )
}

export default DonationForm