import { Divider, Flex, Text, Link as ChakraLink } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/legacy/image";
import Link from "next/link";
import React from "react";
import { MARK_LINK, NODE_HEADING, NODE_HR, NODE_IMAGE, NODE_PARAGRAPH, render } from "storyblok-rich-text-react-renderer";
import { colorToVar, convertColor } from "../../utils/misc";

const RichText = ({ blok, textResolver = undefined }) => {
  const alignment = blok.alignment === 'center' ? 'center' : blok.alignment === 'right' ? 'flex-end' : 'flex-start';
  const color = convertColor(blok.textColor, blok.textSaturation)
  return (
    <Flex 
      {...storyblokEditable(blok)}
      justifyContent={alignment}
      alignItems={alignment}
      direction="column"
      width="full"
    >
      {render(blok.content, {
        textResolver: textResolver,
        nodeResolvers: {
          [NODE_PARAGRAPH]: (children) => (
            <Text 
              as="p" 
              color={color} 
              textShadow={blok.textShadow > 0 ? `2px 2px ${blok.textShadow}px black` : 'none'}
              sx={{textAlign: blok.alignment}}
              _last={{mb: 0}}
            >
              {children}
            </Text>
          ),
          [NODE_HEADING]: (children, {level}) => (
            <Text 
              as={`h${level}` as any} 
              color={color}
              textShadow={blok.textShadow > 0 ? `2px 2px ${blok.textShadow}px black` : 'none'}
              sx={{ textAlign: blok.alignment }}
            >
              {children}
            </Text>
          ),
          [NODE_HR]: () => (
            <Divider />
          ),
          [NODE_IMAGE]: (children, {alt, title, src}:{alt?:string, title?:string, src?:string}) => {
            const dimensions = {
              width: Number(src.split('/')[5].split('x')[0]),
              height: Number(src.split('/')[5].split('x')[1]),
            }

            let ratio = 800 / dimensions.width;
            
            dimensions.width = 800;
            dimensions.height = Math.round(dimensions.height * ratio);
            
            return (
              <Image 
                src={`${src}/m/${dimensions.width}x${dimensions.height}`}
                alt={alt}
                blurDataURL={`${src}/m/100x0`}
                placeholder="blur"
                title={title}
                width={dimensions.width}
                height={dimensions.height}
                className="transition"
              />
            )
          },
        },
        markResolvers: {
          [MARK_LINK]: (children, props):JSX.Element => {
            const { linktype, href, target } = props;

            if (linktype === 'email') {
              // Email links: add `mailto:` scheme and map to <a>
              return (
                (<Link
                  href={`mailto:${href}`}
                  passHref
                  style={{
                    color: `${blok.textColor}`, 
                    textDecoration: 'underline'
                  }}>

                  {children}

                </Link>)
              );
            }

            if (href.match(/^(https?:)?\/\//)) {
              // External links: map to <a>
              return (
                <Link href={href} passHref legacyBehavior>
                  <ChakraLink 
                    isExternal={target === '_blank'} 
                    style={{
                      color: `${blok.textColor}`, 
                      textDecoration: 'underline' 
                    }}
                  >
                    {children}
                  </ChakraLink>
                </Link>
              );
            }
            
            // Internal links: map to <Link>
            return (
              <Link href={href} passHref legacyBehavior>
                <ChakraLink 
                  isExternal={target === '_blank'} 
                  sx={{
                    color: `${blok.textColor}`, 
                    textDecoration: 'underline',
                    ':hover': {
                      fontWeight: 'bold'
                    }
                  }}
                >
                  {children}
                </ChakraLink>
              </Link>
            );
          }
        }
      })}
    </Flex>
  );
};

export default RichText;
