import { Box, Flex, Text } from "@chakra-ui/react"
import { storyblokEditable } from "@storyblok/react"
import Image from "next/legacy/image"
import Link from "next/link"
import { getRealLink } from "../../utils/misc"
import DynamicComponent from "../DynamicComponent"

const ImageCardLink = ({ blok, globalState, setGlobalState, locationState }) => {
  
  if (blok?.link?.cached_url) {
    const [linkHref, target] = getRealLink(blok.link, blok.openInNewWindow)
    
    return (
      <Box
        h={blok.height}
        w="full"
        {...storyblokEditable(blok)}
      >
        <Box
          position="relative"
          rounded={'xl'}
        >
          <Link href={linkHref} target={target} passHref legacyBehavior>
            <ImageCard blok={blok} globalState={globalState} setGlobalState={setGlobalState} locationState={locationState} />
          </Link>
        </Box>
      </Box>
    );
    
  } else {
    return (
      <Box
        h={blok.height}
        w="full"
        {...storyblokEditable(blok)}
      >
        <Box
          position="relative"
          w="full"
          h={blok.height}
          overflow="hidden"
          rounded={'xl'}
        >
          <ImageCard blok={blok} globalState={globalState} setGlobalState={setGlobalState} locationState={locationState} />
        </Box>
      </Box>
    );
  }
}

const ImageCard = ({ blok, globalState, setGlobalState, locationState }) => {
  return (
    <Box h={blok.height}>
      <Image
        src={`${blok?.image.filename}/m/1000x0/smart`}
        alt={blok?.name}
        objectFit='cover'
        objectPosition={'center center'}
        layout="fill"
        blurDataURL={`${blok?.image.filename}/m/10x0`}
        placeholder="blur"
      />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        padding="3"
        width="100%"
        height="100%"
        bgColor="blackAlpha.500"
        rounded={'xl'}
        overflow="hidden"
      >
        <Flex
          direction="column"
          rounded={'xl'}
          overflow="hidden"
          width="100%"
          height="100%"
          padding="3"
          justifyContent={blok.contentVerticalAlign}
        >
          {
            blok.content?.map((nestedBlok, index) => (
              <DynamicComponent blok={nestedBlok} key={index} globalState={globalState} setGlobalState={setGlobalState} locationState={locationState} />
            ))
          }
        </Flex>
      </Box>
    </Box>
  )
}

export default ImageCardLink;