import { storyblokEditable } from "@storyblok/react";
import { Button } from "@chakra-ui/react";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getRealLink } from "../../utils/misc";

const ButtonLink = ({ blok }) => {
  const [linkHref, target] = getRealLink(blok.link, blok.openInNewWindow)

  return linkHref && 
  <Link href={linkHref} passHref {...storyblokEditable(blok)} legacyBehavior>
    <Button 
      as="a"
      sx={{'&:hover': {textDecoration: 'none'}}}
      size={blok.button.size} 
      colorScheme={blok.button.colorScheme} 
      variant={blok.button.variant} 
      fontWeight={blok.button.fontWeight} 
      lineHeight={0}
      width={blok.button.width}
      leftIcon={blok.leftIcon && blok.leftIcon.icon !== '' ? <FontAwesomeIcon icon={[blok.leftIcon.type, blok.leftIcon.icon]}  /> : undefined}
      rightIcon={blok.rightIcon && blok.rightIcon.icon !== '' ? <FontAwesomeIcon icon={[blok.rightIcon.type, blok.rightIcon.icon]}  /> : undefined}
      target={target}
    >
      {blok.button.buttonText}
    </Button>
  </Link>;
}

export default ButtonLink;
