import { Box, Flex, GridItem, LinkBox, LinkOverlay, SimpleGrid, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/legacy/image";
import Link from "next/link";
import DateUtils from '../../utils/dateUtils'

interface Props {
  blok: any;
  globalState: GlobalState;
}

const DisasterResponseList = ({ blok, globalState }:Props):JSX.Element => {
  
  
  let disasters =  globalState.disasters;
  
  if (blok.showPastDisasters) {
    if (globalState?.componentSpecificData?.pastDisasters) {
      disasters = globalState.componentSpecificData.pastDisasters
    }
  }

  if (!disasters) return null;

  return (
    <Flex 
      direction="column" 
      gap="4"
      mb="4"
      {...storyblokEditable(blok)}
    >
      {
        disasters.map(disaster => (
          <LinkBox
            key={disaster.disasterID}
            cursor={'pointer'}
            color="brand.green.600"
            _hover={{
              textDecoration: 'underline',
            }}
            overflow="hidden"
            borderWidth="1px"
            borderRadius={'lg'}
            borderColor="brand.green.600"
            padding="3"
          >
            <Link href={`/response/${disaster.url}`} passHref legacyBehavior>
              <LinkOverlay>
                <SimpleGrid 
                  columns={4}
                  spacing={4}
                  alignItems="center"
                >
                  <GridItem>
                    <Box
                      rounded="xl"
                      overflow="hidden"
                      width="80px"
                      height="60px"
                      position="relative"
                    >
                      <Image
                        src={`https://images.ppm.org/upload/disasterphotos/thumb/${disaster.url}.jpg?v=${disaster.photoVersion}`}
                        alt={disaster.title}
                        layout="fill"
                        objectFit={'cover'}
                      />
                    </Box>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Text fontWeight={'bold'} color="brand.green.600" mb="0">
                      {disaster.title}
                    </Text>
                  </GridItem>
                </SimpleGrid>
              </LinkOverlay>
            </Link>
          </LinkBox>
        ))
      }
    </Flex>
  );
}

export default DisasterResponseList;